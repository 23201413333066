import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import "./BlogBody.css";
import AntDesignBtn from "../AntDesignBtn";
import moment from "moment";
import DOMPurify from "dompurify";
import { Modal, Button } from "react-bootstrap";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";

const BlogBody = ({ post }) => {
  const [copied, setCopied] = useState(false);
  const copyLinkHandler = () => {
    setCopied(true);
    // You can show a notification or handle the copied state as needed
  };

  //
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //
  //
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sanitizedHTML = DOMPurify.sanitize(post?.content?.rendered);
  return (
    <>
      {" "}
      <div className="container-fluid BlogBody">
        <div className="row">
          <span
            className="fs-6"
            style={{
              color: "#3467CB",
            }}
          >
            <Link to="/blog">Blog </Link>
            {">"} {post?.title?.rendered}
          </span>
          <h3 className="col-md-5 col-sm-12 my-3 fw-bold">
            {post?.title?.rendered}
          </h3>
          <p className="fs-6 my-2">
            {moment(post?.date).format("MMMM Do YYYY")}
          </p>
        </div>
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="row py-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizedHTML,
                }}
              />
            </div>

            {/* <div className="row py-3 ">
            <h5 className="fw-bold my-3">
              Case Studies in Sustainable Cities:
            </h5>
            <ol className="fs-6 px-3">
              <li className="font_15 my-1">
                Singapore - The Garden City: Singapore is a remarkable example
                of urban greening. The city-state has invested in lush green
                spaces, including the famous Gardens by the Bay, which not only
                enhance the city's aesthetics but also its sustainability.
              </li>
              <li className="font_15 my-1">
                Copenhagen - A Cycling Haven: Copenhagen has integrated urban
                green spaces with its bicycle-friendly infrastructure, making it
                one of the world's most sustainable cities. Parks and cycling
                lanes coexist harmoniously, reducing traffic congestion and air
                pollution.
              </li>
              <li className="font_15 my-1">
                Portland - Eco-Friendly Roofs: Portland, Oregon, has embraced
                green roofs, which help regulate indoor temperatures and provide
                attractive spaces for residents.
              </li>
            </ol>
          </div>
          <div className="row py-3 ">
            <h5 className="fw-bold my-3">Challenges and Future Directions:</h5>
            <span className="font_15 my-1">
              While urban greening projects hold great promise, they also face
              challenges, such as land scarcity and funding. However, with
              increasing awareness of the benefits and a growing emphasis on
              sustainability, many cities worldwide are actively pursuing such
              initiatives
            </span>
          </div>
          <div className="row py-3 ">
            <h5 className="fw-bold my-3">Conclusion:</h5>
            <span className="font_15 my-1">
              Urban greening projects are not just about adding greenery to
              cities; they are about transforming urban landscapes into
              sustainable environments that improve the quality of life for
              residents and the health of our planet. By investing in urban
              greening initiatives, cities can take a significant step toward
              creating a more sustainable and harmonious future for all. As we
              continue to confront the challenges of urbanization, embracing
              nature within our cities will be an essential part of the
              solution.
            </span>
          </div> */}
            <div className="row">
              <div className="col-md-4 col-sm-4 my-3" onClick={handleShow}>
                <AntDesignBtn text={"SHARE"} icons={<FaShareAlt />} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Pop Up */}
      <Modal
        show={show}
        onHide={handleClose}
        style={{
          backgroundColor: "#FFFFFFD6",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className=" mb-2"
            style={{
              fontSize: "22px",
              color: "#121212",
              fontWeight: "700",
            }}
          >
            Share this property
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <span
              style={{
                color: "#121212",
                size: "15px",
                fontWeight: "400",
              }}
            >
              Share this link via
            </span>
          </div>
          <div className="row mt-5 gap-5">
            <div className="col-1 share_modal">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF style={{ color: "#2774DC", fontSize: "22px" }} />
              </a>
            </div>
            <div className="col-1 share_modal">
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter style={{ color: "#46C2F8", fontSize: "22px" }} />
              </a>
            </div>
            {/* <div className="col-1 share_modal">
            <FaInstagram style={{ color: "#D04B7B", fontSize: "22px" }} />
          </div> */}
            <div className="col-1 share_modal">
              <a
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp style={{ color: "#5BB681", fontSize: "22px" }} />
              </a>
            </div>
            <div className="col-1 share_modal">
              <a
                href={`https://t.me/share/url?url=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegramPlane
                  style={{ color: "#0689C4", fontSize: "22px" }}
                />
              </a>
            </div>
          </div>
          <div className="row my-4">
            <span>Or copy link</span>
          </div>
          <div
            className="d-flex gap-4  flex-row"
            style={{
              border: "1px solid #D7D7D7",
              borderRadius: "5px",
              padding: "5px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img src="/link.svg" alt="hh" />
            <span
              style={{
                fontSize: !isMobile ? "13px" : "9px",
                color: "#7A7A7A",
              }}
            >
              https://joetoniruest.com/selected-blog/
              {post?.slug}
            </span>
            <CopyToClipboard
              text={window.location.href}
              onCopy={copyLinkHandler}
            >
              <Button
                style={{
                  color: "#000000",
                  backgroundColor: "#FBC344",
                  borderColor: "#FBC344",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px 10px",
                  paddingBottom: "8px",
                }}
              >
                {copied ? "Copied!" : "Copy"}
              </Button>
            </CopyToClipboard>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BlogBody;
