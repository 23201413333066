import React, { useEffect, useState } from "react";

import "./Highlight.css";
import Inputs from "../Inputs";
import { Link } from "react-router-dom";
import moment from "moment";
import DOMPurify from "dompurify";

const Highlight = ({ blog }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 460);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const highlighted = blog
    ?.filter((blog) => blog.categories[0] === 1)
    ?.slice(0, 1)
    .map((blog) => blog);
  console.log(highlighted[0]);

  const sanitizedHTML = DOMPurify.sanitize(highlighted[0]?.content?.rendered);
  return (
    <div className="container-fluid Highlight">
      <div className="row py-4 mb-5 flex-row justify-content-between align-items-center">
        <div className="col-md-3 col-sm-6">
          <h2
            className=""
            style={{
              marginBottom: isMobile ? "2rem" : "",
            }}
          >
            Highlights
          </h2>
        </div>
        <div className="col-md-5 col-sm-6">
          <Inputs text={"Search for an article"} />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6 col-sm-12 pt-5">
          <p
            className="mt-2"
            style={{
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            {moment(highlighted[0]?.date).format("MMMM Do YYYY")}
          </p>
          <h5
            className="mt-2"
            style={{
              fontSize: isMobile ? "18px" : "24px",
              fontWeight: "700",
            }}
          >
            {highlighted[0]?.title?.rendered}
          </h5>
          <p
            className="mt-2 span_12"
            style={{
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizedHTML.slice(0, 130) + "...",
              }}
            />
          </p>
          <Link to={`/${"selected-blog"}/${highlighted[0]?.slug}`}>
            <button
              className="btn btn-outline-light mt-5 px-5"
              style={{
                fontSize: "15px",
                fontWeight: "500",
                padding: "10px 20px",
              }}
            >
              READ MORE
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Highlight;
