import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { FaSolarPanel } from "react-icons/fa";
import moment from "moment";
import DOMPurify from "dompurify";
import axios from "axios";

const Slide2Mob = ({ blogPage, url_str, homeBg, blogs }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [catPost, setCatPost] = useState("");
  const [categories, setCategories] = useState("");

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % blogs.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? blogs.length - 1 : prev - 1));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://tomiwao58.sg-host.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,categories"
        );
        setCatPost(response.data);

        // Fetch categories
        const categoriesResponse = await axios.get(
          "https://tomiwao58.sg-host.com/wp-json/wp/v2/categories"
        );
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const filteredBlogs = blogs.filter((blog) => blog.categories[0] === 4);

  return (
    <div className="weekly-mobile-slider">
      {filteredBlogs.map((blog, i) => {
        const sanitizedHTML = DOMPurify.sanitize(blog?.content?.rendered);
        return (
          <div
            key={i}
            className={`slide ${currentSlide === i ? "active" : ""}`}
          >
            <Link
              to={`/${url_str}/${blog?.slug}`}
              style={{
                margin: "0 1rem",
              }}
            >
              <Card className="swipe_card_with">
                <Card.Img
                  variant="top"
                  src={blog?._embedded?.["wp:featuredmedia"][0]?.source_url}
                  style={{
                    width: "inherit",
                    height: "230px",
                  }}
                />
                <Card.Body
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "1rem 0.9rem",
                    minHeight: "205px",
                  }}
                  className="card_body py-3"
                >
                  <Card.Title>
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {moment(blog?.date).format("MMMM Do YYYY")}
                    </span>
                  </Card.Title>
                  <div className="py-2 d-flex gap-2">
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                    >
                      <b>{blog?.title?.rendered.slice(0, 47)}...</b>
                    </span>
                  </div>

                  <div
                    className={`my-3`}
                    style={{
                      borderBottom: "2px solid #D7D7D7",
                    }}
                  ></div>

                  <Card.Text
                    className=" py-1"
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#121212",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizedHTML.slice(0, 80) + "...",
                      }}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>
        );
      })}
      {filteredBlogs?.length > 1 && (
        <>
          <button
            className="button-prev-1"
            onClick={handlePrevSlide}
            disabled={currentSlide === 0}
          >
            {/* <AiFillBackward /> */}
            <img src="./slideleft.svg" alt="" />
          </button>
          <button
            className="button-prev-2"
            onClick={handleNextSlide}
            disabled={currentSlide === filteredBlogs.length - 1}
          >
            <img src="./slideright.svg" alt="" />
          </button>
        </>
      )}
    </div>
  );
};

export default Slide2Mob;
