import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation, Autoplay } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

//
import "swiper/css/navigation";

import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { FaSolarPanel } from "react-icons/fa";
import moment from "moment";
import DOMPurify from "dompurify";
import axios from "axios";

SwiperCore.use([Navigation, Autoplay]);

const Slide2 = ({ blogPage, url_str, homeBg, blogs }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //
  const [catPost, setCatPost] = useState("");
  const [categories, setCategories] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://tomiwao58.sg-host.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,categories"
        );
        setCatPost(response.data);

        // Fetch categories
        const categoriesResponse = await axios.get(
          "https://tomiwao58.sg-host.com/wp-json/wp/v2/categories"
        );
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Swiper
      className="swiper-container"
      spaceBetween={isMobile ? 3 : 30}
      slidesPerView={isMobile ? 1 : 3}
      touchStartPreventDefault={false}
      userAgent={navigator.userAgent}
      navigation
      autoplay={{ delay: 4000, disableOnInteraction: false }}
    >
      {blogs
        ?.filter((blog) => blog.categories[0] === 4)
        .map((blog, index) => {
          // console.log(blog?._embedded?.["wp:featuredmedia"][0]?.source_url);

          const sanitizedHTML = DOMPurify.sanitize(blog?.content?.rendered);
          return (
            <SwiperSlide key={index}>
              {/* <Link to={`/${url_str}/${blog?.slug}`}> */}
              <Link
                to={`/${url_str}/${blog?.slug}`}
                style={{
                  margin: "0 1rem",
                }}
              >
                <Card
                  className="swipe_card_with"
                  // style={{ width: isMobile ? "24rem" : "" }}
                >
                  <Card.Img
                    variant="top"
                    src={blog?._embedded?.["wp:featuredmedia"][0]?.source_url}
                    style={{
                      width: "inherit",
                      height: "230px",
                    }}
                  />
                  {/* <Card.Img variant="top" src="/first-slide-img.png" /> */}
                  <Card.Body
                    style={{
                      backgroundColor: "#f9f9f9",
                      padding: isMobile ? "1rem 0.9rem" : "1rem 1.6rem",
                      minHeight: isMobile ? "205px " : "270px",
                    }}
                    className="card_body py-3"
                  >
                    <Card.Title>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {moment(blog?.date).format("MMMM Do YYYY")}
                        {/* October 31, 2023 */}
                      </span>
                    </Card.Title>
                    <div className="py-2 d-flex gap-2">
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                      >
                        <b>
                          {blog?.title?.rendered.slice(0, 47)}...
                          {/* How urban greening projects affect sustainable cities */}
                        </b>{" "}
                      </span>
                    </div>

                    <div
                      className={`my-3`}
                      style={{
                        borderBottom: "2px solid #D7D7D7",
                      }}
                    ></div>

                    <Card.Text
                      className=" py-1"
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#121212",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHTML.slice(0, 80) + "...",
                        }}
                      />
                      {/* Learn how real estate technology can drive value in
                      commercial real estate by enhancing... */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default Slide2;
