import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// const options = Array(10).fill(0).map((_, i) => ({
//   label: ((i + 1) * 10000000).toLocaleString(),
//   value: (i + 1) * 10000000,
// }));

const SelectTwoNew = ({ name, onChange, value, options, label }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="minPrice-label" className="price_edited">
        {label}
      </InputLabel>
      <Select
        labelId="minPrice-label"
        id="minPrice"
        name={name}
        value={value}
        onChange={onChange}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectTwoNew;
