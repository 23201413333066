import React, { useEffect, useState } from "react";

import Footer from "../components/Footer/Footer";
import NewsLetter from "../components/CantFind/NewsLetter";
import SelectedBlogHero from "../components/Hero/SelectedBlogHero";
import BlogBody from "../components/BlogBody/BlogBody";
// import AllProperties from "../components/AllProperties/AllProperties";
import FloatWhatsapp from "../components/FloatWhatsapp/FloatWhatsapp";
import axios from "axios";
import { useParams } from "react-router-dom";
import AllBlogs from "../components/AllProperties/AllBlogs";

const SelectedBlog = () => {
  const [newLetterComp, setNewLetterComp] = useState(true);
  // const [allProps, setAllProps] = useState(true);
  const [allProps, setAllProps] = useState(true);
  const [blogPage, setBlogPage] = useState(true);

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const url_str = "selected-blog";
  //
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://blogadmin.joetoniruest.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,categories&slug=${slug}`
        );
        setPost(response.data[0]);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [slug]);
  // console.log(post);

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://blogadmin.joetoniruest.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,categories"
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <SelectedBlogHero />
      <BlogBody post={post} />
      <AllBlogs
        allProps={allProps}
        blogPage={blogPage}
        url_str={url_str}
        blogs={posts}
      />
      <NewsLetter
        newLetterComp={newLetterComp}
        additional_details="Add me to the newsletter"
      />
      <Footer />
      <FloatWhatsapp />
    </>
  );
};

export default SelectedBlog;
