import React, { useEffect, useState } from "react";

import Buttons from "../Buttons";

const AboutBanner = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 460);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="container-fluid about_banner p-5 ">
        <div className="row mt-5">
          <div
            className="justify-content-between"
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "2rem" : "",
            }}
          >
            <h2 style={{ textAlign: isMobile ? "center" : "" }}>
              Find Your Dream Home
            </h2>
            <Buttons
              text={"LET’S CONNECT"}
              style={{
                backgroundColor: "#FBC344",
                border: "1px solid #FBC344",
                color: "#000",
                width: isMobile ? "250px" : "400px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid  bg-boss-1 ">
        <div className="row">
          <div className="col-md-5 py-5x px-5x dak_p">
            <h2 className="my-5x">Meet The Founder</h2>
            <p className="font_15 ">
              Chidi Etoniru is a seasoned real estate entrepreneur with over a
              decade of expertise in brokerage, facility management, valuation,
              and property development. Currently, he holds the position of Vice
              Chairman at the Lagos Chapter of the Real Estate Developers
              Association of Nigeria (REDAN). Chidi's professional journey began
              at Prime Atlantic Cegelec (PACE), an oil and gas company in
              Victoria Island, Lagos, where he led the facility management unit.
              He extended his facility management acumen to Epsilon Estate, a
              subsidiary of C&M Exchange Limited. <br />
              In 2012, Chidi established Joe Etoniru and Associates in Lagos
              State, initially focusing on real estate brokerage. Over the
              years, he has strategically expanded the firm’s services to
              include property valuation, real estate advisory, and property
              management.
              <br /> In 2017, Chidi founded JEA Projects, a company dedicated to
              developing contemporary smart homes in Nigeria that adhere to
              international standards. Demonstrating his entrepreneurial
              versatility, he has also launched businesses in home moving, pest
              control, and cleaning, catering to diverse needs across the real
              estate value chain. Chidi holds a Bachelor's degree in Estate
              Management and a Master's degree in Facility Management, both from
              the University of Lagos. He is a chartered Estate Surveyor and
              Valuer with the Nigerian Institution of Estate Surveyors and
              Valuers (NIESV) and a member of the Certified Institute of
              Auctioneers.
            </p>
            <p className="p-1">
              "He is the current vice chairman of Real Estate Developers
              Association of Nigeria(REDAN)."
            </p>
            {/* <p className="font_15">
              Keen, attentive, and versatile are clear adjectives that describe
              Mr. Chidi Etoniru. A real estate aficionado with over two decades
              of experience in facility management, real estate brokerage,
              valuation and property development. Mr. Etoniru began his career
              as head of a facility management unit in Prime Atlantic Cegelec
              (PACE), an oil and gas company in Victoria Island, Lagos and from
              there transitioned to Real Estate in 2012. Having developed an eye
              for quality service and customer satisfaction through his career.
              He launched Joe Etoniru and Associates in Lagos State to carve out
              a better and more convenient path for people to find good homes.
              After this, Mr. Etoniru started developing contemporary properties
              to set a standard for luxury home construction in Lagos. He also
              branched into other aspects of the value chain including moving,
              renting, pest control and home/facility cleaning. A graduate of
              University of Lagos where he got a bachelors in Estate Management.
              Mr. Etoniru sets himself apart by constant self improvement, smart
              investments, and a constant need to stay in the know as the Real
              Estate market evolves. He is a chartered Estate Surveyor and
              Valuer with the Nigerian Institution of Estate Surveyors and
              Valuers (NIESV). He is also a member of the Certified Institute of
              Auctioneers
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutBanner;

// new_prop_about.png
