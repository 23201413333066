import React, { useEffect, useState } from "react";

import Footer from "../components/Footer/Footer";
import NewsLetter from "../components/CantFind/NewsLetter";
import AllProperties from "../components/AllProperties/AllProperties";
import RecentBlog from "../components/AllProperties/RecentBlog";
// import WeeklyProps from "../components/WeeklyProps/WeeklyProps";
import BlogHero from "../components/Hero/BlogHero";
import Highlight from "../components/Highlight/Highlight";
import HomeBlog from "../components/WeeklyProps/HomeBlog";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../features/Property/propertySlice";
import AllBlogs from "../components/AllProperties/AllBlogs";
import axios from "axios";

const Blogs = () => {
  const dispatch = useDispatch();
  const blogsState = useSelector((state) => state.property);

  const blogs = blogsState?.blogs?.blog || [];

  //
  const [newLetterComp, setNewLetterComp] = useState(false);
  const [allProps, setAllProps] = useState(true);
  const [blogPage, setBlogPage] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  const url_str = "selected-blog";

  // test
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://blogadmin.joetoniruest.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,categories"
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  // https://blogadmin.joetoniruest.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,categories

  return (
    <>
      <BlogHero />
      <Highlight blog={posts} />
      <HomeBlog blogPage={blogPage} blogs={posts} url_strblog={url_str} />
      <RecentBlog blog={posts} />
      <AllBlogs
        allProps={allProps}
        blogPage={blogPage}
        url_str={url_str}
        blogs={posts}
      />
      <NewsLetter newLetterComp={newLetterComp} blogPage={blogPage} />
      <Footer />
    </>
  );
};

export default Blogs;
