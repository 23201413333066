// SliderMobile
import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { AiFillBackward, AiFillForward } from "react-icons/ai";

const SliderMobile = ({ propertyOfTheWeek, url_str }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % propertyOfTheWeek.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? propertyOfTheWeek.length - 1 : prev - 1
    );
  };

  return (
    <div className="weekly-mobile-slider">
      {propertyOfTheWeek.map((property, i) => (
        <div key={i} className={`slide ${currentSlide === i ? "active" : ""}`}>
          <Link to={`/${url_str}/${property.slug}`}>
            <Card className="swipe_card_with" style={{ width: "20rem" }}>
              {property?.images && property.images.length > 0 && (
                <Card.Img
                  variant="top"
                  src={property.images[0]}
                  style={{
                    width: "inherit",
                    height: "260px",
                  }}
                />
              )}
              <Card.Body
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "1rem 0.9rem",
                }}
                className="card_body py-3"
              >
                <Card.Title>
                  <b
                    style={{
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                    {property?.title}
                  </b>
                </Card.Title>
                <div className="py-3 d-flex gap-2">
                  <img src="./location_icon.svg" alt="" />
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#121212",
                    }}
                  >
                    {property?.location} |{" "}
                    <b
                      style={{
                        fontWeight: "700",
                        color: "#121212",
                      }}
                    >
                      {new Intl.NumberFormat("en-NG", {
                        style: "currency",
                        currency: "NGN",
                        minimumFractionDigits: 0,
                      }).format(property?.price)}
                    </b>{" "}
                  </span>
                </div>

                <div
                  className={`my-2`}
                  style={{
                    borderBottom: "2px solid #D7D7D7",
                  }}
                ></div>

                <Card.Text
                  className=" py-1"
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#121212",
                  }}
                >
                  <b
                    style={{
                      fontSize: "700",
                      color: "#121212",
                    }}
                  >
                    Features:
                  </b>{" "}
                  {property?.description?.slice(0, 72) ?? ""} ...
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </div>
      ))}

      {propertyOfTheWeek?.length > 1 && (
        <>
          <button className="button-prev-1" onClick={handlePrevSlide}>
            {/* <AiFillBackward /> */}
            <img src="./slideleft.svg" alt="" />
          </button>
          <button className="button-prev-2" onClick={handleNextSlide}>
            <img src="./slideright.svg" alt="" />
          </button>
        </>
      )}
    </div>
  );
};

export default SliderMobile;
