import React, { useState } from "react";
import Card from "react-bootstrap/Card";

import "./slideMobile.css";

const ProjAndPropSlideMobile = ({
  homePage,
  propertyDetail,
  widthxs,
  setDisplayImg,
  handleShow1,
  selectedProjPage,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % propertyDetail.images.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? propertyDetail.images.length - 1 : prev - 1
    );
  };

  return (
    <div className="mobile-slider">
      {propertyDetail?.images?.slice(0, -1).map((image, i) => (
        <div
          key={i}
          className={`slide ${currentSlide === i ? "active" : ""}`}
          onClick={() => {
            setDisplayImg(i);
            if (selectedProjPage) {
              handleShow1();
            }
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <img
            src={image}
            style={{
              width: "100%",
              height: "140px",
              borderRadius: "10px",
              border: "2px solid grey",
            }}
          />
        </div>
      ))}
      <button className="slide-mob-btn" onClick={handlePrevSlide}>
        Prev
      </button>
      <button className="slide-mob-btn" onClick={handleNextSlide}>
        Next
      </button>
    </div>
  );
};

export default ProjAndPropSlideMobile;
