import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";

import "./AllProperties.css";
import { Link } from "react-router-dom";
import moment from "moment";
import DOMPurify from "dompurify";

const RecentBlog = ({ blogPage, blog }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 460);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container-fluid AllProperties">
      <div className="row">
        <div className="col-12 py-2">
          <h2>Recent Blog Posts</h2>
        </div>
        <div className="row justify-content-between my-3">
          {blog
            ?.filter((blog) => blog.categories[0] === 5)
            ?.slice(0, 1)
            .map((blog) => {
              const sanitizedHTML = DOMPurify.sanitize(blog?.content?.rendered);
              return (
                <div className="col-md-6 col-sm-12 my-2">
                  <Link to={`/${"selected-blog"}/${blog?.slug}`}>
                    <Card>
                      <Card.Img
                        variant="top"
                        src={
                          blog?._embedded?.["wp:featuredmedia"][0]?.source_url
                        }
                        // src="./first-slide-img.png"
                        style={{
                          height: "270px",
                        }}
                      />
                      <Card.Body
                        className=" px-4"
                        style={{
                          backgroundColor: "#f9f9f9",
                          padding: isMobile ? "10px 10px" : "20px 20px",
                          // height: "200px",
                        }}
                      >
                        <Card.Title className="pb-3">
                          <span>
                            {moment(blog?.date).format("MMMM Do YYYY")}
                          </span>
                        </Card.Title>
                        <div className="py-1">
                          <h5>{blog?.title?.rendered}</h5>
                          <div
                            className="mb-2"
                            style={{
                              borderBottom: "2px solid #D7D7D7",
                              marginTop: isMobile ? "1.4rem" : "2.6rem",
                            }}
                          ></div>
                        </div>
                        <Card.Text
                          className="py-1"
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                          }}
                        >
                          <b>Features:</b>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: sanitizedHTML.slice(0, 90) + "...",
                            }}
                          />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              );
            })}
          <div className="col-md-6 col-sm-12 my-2">
            {blog
              ?.filter((blog) => blog.categories[0] === 5)
              ?.slice(1, 2)
              .map((blog) => {
                const sanitizedHTML = DOMPurify.sanitize(
                  blog?.content?.rendered
                );
                return (
                  <Link
                    // to="/selected-blog"
                    to={`/${"selected-blog"}/${blog?.slug}`}
                  >
                    <div
                      className="d-flex mb-2 card-xRow"
                      style={{
                        flexDirection: isMobile ? "column" : "row",
                      }}
                    >
                      <img
                        variant="top"
                        src={
                          blog?._embedded?.["wp:featuredmedia"][0]?.source_url
                        }
                        // src="./first-slide-img.png"
                        className="fit-content card-xImg"
                        style={{
                          width: isMobile ? "100%" : "50%",
                          height: isMobile ? "250px" : "",
                        }}
                      />
                      <div
                        className="card-xbody px-4"
                        style={{
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <p className="my-2 card-xP">
                          <span>
                            {moment(blog?.date).format("MMMM Do YYYY")}
                          </span>
                        </p>
                        <div className="py-2 my-2">
                          <h5>{blog?.title?.rendered.slice(0, 47)}...</h5>
                        </div>
                        <div
                          className="my-3"
                          style={{ borderBottom: "2px solid #D7D7D7" }}
                        ></div>
                        <span className="py-1">
                          <b>
                            Features:{" "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedHTML?.slice(0, 90) + "...",
                              }}
                            />
                          </b>
                        </span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            {blog
              ?.filter((blog) => blog.categories[0] === 5)
              ?.slice(2, 3)
              .map((blog) => {
                const sanitizedHTML = DOMPurify.sanitize(
                  blog?.content?.rendered
                );
                return (
                  <Link to={`/${"selected-blog"}/${blog?.slug}`}>
                    <div
                      className="d-flex mb-3 card-xRow"
                      style={{
                        flexDirection: isMobile ? "column" : "row",
                      }}
                    >
                      <img
                        variant="top"
                        // src="./first-slide-img.png"
                        src={
                          blog?._embedded?.["wp:featuredmedia"][0]?.source_url
                        }
                        className="fit-content card-xImg"
                        style={{
                          width: isMobile ? "100%" : "50%",
                          height: isMobile ? "250px" : "",
                        }}
                      />
                      <div
                        className="card-xbody px-4"
                        style={{
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <p className="my-2 card-xP">
                          <span>
                            {moment(blog?.date).format("MMMM Do YYYY")}
                          </span>
                        </p>
                        <div className="py-2 my-2">
                          <h5>{blog?.title?.rendered.slice(0, 47)}...</h5>
                        </div>
                        <div
                          className="my-3"
                          style={{ borderBottom: "2px solid #D7D7D7" }}
                        ></div>
                        <span className="py-1">
                          <b>
                            Features:{" "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizedHTML?.slice(0, 90) + "...",
                              }}
                            />
                          </b>
                        </span>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentBlog;
